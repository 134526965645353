import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { logos } from "./HeaderLogos";
import styled from "styled-components";
import { device } from "../../utils";

const StyledDiv = styled.div`
    & img.header-logo {
        height: 24px;
        @media ${device.sm} {
            height: 40px !important;
        }
        @media ${device.md} {
            height: 40px !important;
        }
        @media ${device.lg} {
            height: 40px !important;
        }
        @media ${device.xl} {
            height: 48px !important;
        }
        @media ${device.xxl} {
            height: 48px !important;
        }
    }
`

const NavBarSimpleWithLogos = () => {
    
    return (
        <StyledDiv>
            <Container className="mb-4 mt-15">
                <Row className="align-items-center">
                    <Col className="col-lg-6">
                        <div className="brand">
                            <h1 className="site-title font-family-primary font-size-7 mb-0 text-center text-sm-left">
                                COFRA Careers
                            </h1>
                        </div>
                    </Col>
                    <Col className="logos col-12 col-lg-6 align-self-lg-center mb-lg-n5 ml-lg-auto mt-10 mt-lg-0">
                        <div className="d-flex align-items-end justify-content-between pl-lg-5 px-5 px-lg-0  mt-n8">
                            { logos.map((item) =>
                                (
                                    <img
                                        class={`${item.class}`}
                                        src={item.src}
                                        alt={item.alt}
                                        style={item.style}
                                    />
                                )
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledDiv>
    )
}

export default NavBarSimpleWithLogos