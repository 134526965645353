import React from "react"
import { UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react"
import NoAuthRedirect from "../../../components/Core/NoAuthRedirect"
import PageWrapper from "../../../components/PageWrapper"
import SEO from "../../../components/seo"
import { Container, Row } from "react-bootstrap"
import NavBarSimpleWithLogos from "../../../components/Header/NavBarSimpleWithLogos"
import BackButton from "../../../components/Core/BackButton"

const headerConfig = {
    render: false
  };
const footerConfig = {
    render: false
  };
const formUrl = new URL('https://forms.office.com/Pages/ResponsePage.aspx')
  formUrl.searchParams.set('id', process.env.GATSBY_MENTORSHIP_FORM_MENTOR_ID)
  formUrl.searchParams.set('embed', true)

  const MentorSignUp = ({location}) => {
    return (
        <>
            <UnauthenticatedTemplate>
                <NoAuthRedirect location={location} />
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <PageWrapper 
                    headerConfig={headerConfig} 
                    footerConfig={footerConfig}
                >
                    <SEO title="Sign up to become a mentor" />
                    <NavBarSimpleWithLogos />
                    <BackButton />
                    <Container>
                        <Row className="justify-content-center">
                            <iframe id="sign-up-form-iframe" width="640px" height="1480px" src={formUrl.href} frameborder="0" marginwidth="0" marginheight="0" style={{border: "none", minWidth: "320px", maxWidth:"100%", maxHeight:"90vh"}} allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe>
                        </Row>
                    </Container>
                </PageWrapper>
            </AuthenticatedTemplate>
        </>
    )
}

export default MentorSignUp