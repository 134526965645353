import React from "react";
import LogoAnthos from "../../assets/image/logo_anthos_black_baselined.svg"
import LogoAfam from "../../assets/image/logo_afam_black_baselined.svg"
import LogoCofra from "../../assets/image/logo_cofra_black_baselined.svg"
import LogoSunrock from "../../assets/image/logo_sunrock_black_baselined.svg"

export const logos = [
    {
        name: "afam",
        src: LogoAfam,
        href: 'https://www.anthosam.com/',
        title: 'See Anthos Fund and Asset Management website',
        alt: 'Logo Anthos Fund and Asset Management',
        class: 'logo-afam header-logo'
    },
    {
        name: "anthos",
        src: LogoAnthos,
        href: 'https://www.cofraholding.com/en/family/#anthosTheFamilyOffice',
        title: 'See Anthos Family Office website',
        alt: 'Logo Anthos Family Office',
        class: 'logo-anthos header-logo'
    },
    {
        name: "cofra",
        src: LogoCofra,
        href: 'https://www.cofraholding.com/',
        title: 'See COFRA Holding website',
        alt: 'Logo COFRA Holding',
        class: 'logo-cofra header-logo'
    },
    {
        name: "sunrock",
        src: LogoSunrock,
        href: 'https://sunrock.com/',
        title: 'See Sunrock website',
        alt: 'Logo Sunrock',
        class: 'logo-sunrock header-logo'
    }
]